// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq_Lj5C7{padding:0 8px 8px}.faq_Lj5C7 h4{margin-left:8px}.faq__input_qwVM9{margin:0 8px 10px}.faq__accordion-content-wrapper_lHu8m{padding:0 4px}html.app__layout--desktop .faq_Lj5C7{padding:0 24px 24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq": `faq_Lj5C7`,
	"faq__input": `faq__input_qwVM9`,
	"faq__accordion-content-wrapper": `faq__accordion-content-wrapper_lHu8m`
};
export default ___CSS_LOADER_EXPORT___;
