// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .callback_j_LCx{padding:0 24px 32px}html.app__layout--desktop .callback__form_Xjt4q{padding:0 8px}html.app__layout--desktop .callback__text-content_Po0Zv{padding:0 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callback": `callback_j_LCx`,
	"callback__form": `callback__form_Xjt4q`,
	"callback__text-content": `callback__text-content_Po0Zv`
};
export default ___CSS_LOADER_EXPORT___;
