// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback_DfXG8{padding:0 16px 16px}html.app__layout--desktop .feedback_DfXG8{padding:0 32px 32px}.feedback__hint_ysJZf{margin:16px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback": `feedback_DfXG8`,
	"feedback__hint": `feedback__hint_ysJZf`
};
export default ___CSS_LOADER_EXPORT___;
