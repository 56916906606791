import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
import SupportModalTopBarRouteComponent from 'web/src/modules/support/views/SupportModalTopBarRouteComponent/SupportModalTopBarRouteComponent.vue';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFaqCategoryTopBarRouteComponent',
    setup (__props) {
        const support = useSupport();
        const router = useRouter();
        const supportFaqStore = useSupportFaqStore();
        const component = SupportModalTopBarRouteComponent;
        const title = computed(()=>{
            const faqCategory = support.categoryByUrlId(String(router.getParam('category')) || '');
            return faqCategory?.title || '';
        });
        function onBackClick() {
            supportFaqStore.setIsYandexMetrikaAllowed(false);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), {
                title: title.value,
                onBack: onBackClick
            }, null, 40, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'SupportFaqCategoryTopBarRouteComponent'
                ]
            ]);
        };
    }
});
