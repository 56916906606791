// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq_uNpHE{padding:0 8px 8px 8px}html.app__layout--desktop .faq_uNpHE{padding:0 24px 32px 24px}.faq__search_fr7KB{margin:0 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq": `faq_uNpHE`,
	"faq__search": `faq__search_fr7KB`
};
export default ___CSS_LOADER_EXPORT___;
