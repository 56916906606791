import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onMounted, onBeforeUnmount } from 'vue';
import { VLoader } from '@components/loader';
import { VForm } from 'web/src/components/Form';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import { useSupportFeedback } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFeedbackRoutePage',
    setup (__props) {
        const { loaded, schema, uiSchema, isPending, onSubmit, runOnMounted, runBeforeUnmount } = useSupportFeedback();
        onMounted(runOnMounted);
        onBeforeUnmount(runBeforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style.feedback),
                "data-test-id": "feedback-wrapper"
            }, [
                _unref(loaded) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 0,
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isPending),
                    onSubmit: _unref(onSubmit)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onSubmit"
                ])) : (_openBlock(), _createBlock(_unref(VLoader), {
                    key: 1
                })),
                _createVNode(VHintBlock, {
                    label: _ctx.$t('WEB2_CONTACTFORM_IMP_MSG_NO_REPEAT'),
                    class: _normalizeClass(_ctx.$style.feedback__hint)
                }, null, 8, [
                    "label",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SupportFeedbackRoutePage'
                ]
            ]);
        };
    }
});
